import React, {useRef, useState, useEffect} from 'react'
import {Link} from 'gatsby'
import {DatePicker, Spin} from 'antd'
import { ConfigProvider } from 'antd';
import "moment/locale/ru";
import locale from "antd/es/locale/ru_RU";
import moment from "moment";
import { IMaskInput } from 'react-imask';
import classnames from 'classnames';
import googleLogo from '@assets/svg/google.svg'
import yandexLogo from '@assets/svg/yandex.svg'
import fileSvg from '@assets/svg/file.svg'
import warningSvg from '@assets/svg/warning.svg'
import removeFileSvg from '@assets/svg/remove-file.svg'
import successSvg from '@assets/svg/success.svg'

import * as styles from './form.module.scss'

const ReviewIntegrationForm = ({
    restaurantName, pageId, beforeRatingText, gooleReviewLink, yandexReviewLink, yandexReviewWidget
}) => {
    const inputTel = useRef(null);
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [bot, setBot] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [rating, setRating] = useState(0);
    const [success, setSuccess] = useState(false);
    const [datePickerValue, setDatePickerValue] = useState(moment());

    const removeFile = (index) => {
        
        if(!inputFile) return;

        const newFiles = files.filter((file, i) => i !== index);

        setFiles(newFiles);
        // validFileInput(newFiles);
    }

    const disabledDate = current => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    function isFormFieldValueValid(field) {
        switch (field.dataset.type || field.type) {
            case 'tel':
                return field.value.length >= 17;
            case 'email':
                return String(field.value).toLowerCase().match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            default:
                return !!field.value.length;
        }
    }

    function isFormFieldValid(field) {
        const valid = isFormFieldValueValid(field);
        if(!valid) {
            field.classList.add('not-valid')
        } else {
            field.classList.remove('not-valid');
        }
        return valid;
    }

    function isFormValid(form){

        let isValid = true;
        const requiredFields = form.querySelectorAll('[data-required]');
        for(let field of requiredFields) {
            if(!isFormFieldValid(field)) {
                isValid = false;
            }
        }

        return isValid;
    }

    function resetEmptyFields(form){
        const fields = form.querySelectorAll('.not-empty');
        for(let field of fields) {
            field.classList.remove('not-empty');
        }
    }

    function resetChecked(form){
        const fields = form.querySelectorAll(':checked')
        for(let field of fields) {
            field.checked = false;
        }
    }

    function handleReset(e) {
        const form = e.target;
        setFiles([]);
        setRating(0);
        setDatePickerValue(null);
        resetEmptyFields(form);
        resetChecked(form);
    }

    function handleSubmit(e) {
        const form = e.target;
        e.preventDefault();
        setFormErrors([]);
        if(!isFormValid(form)) {
            return setFormErrors(['Заполните все необходимые поля!'])
        }
        if(bot) return;

        let headers = new Headers();
        const isBrowser = typeof window !== 'undefined';
        const postUrl = process.env.GATSBY_REVIEW_ADD_API;
        const username = process.env.GATSBY_HTTP_AUTH_USERNAME;
        const password = process.env.GATSBY_HTTP_AUTH_PASSWORD;

        isBrowser && headers.set("Authorization", 'Basic ' + window.btoa(username + ':' + password));

        setSuccess(false);
        setLoading(true);

        fetch(postUrl, {
            method: 'post',
            headers: headers,
            body: new FormData(form),
        })
        .then(res => res.json())
        .then(res => {
            
            if(res.success) {

                form.reset();
                setSuccess(true);
    
            }

            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    function handleRatingChanged(e) {
        const val = e.target.value;
        if(!e.target.value) return;
        setRating(val);
    }

    function validFileInput(files) {
        if(files.length > 3) {
            inputFile.current.parentNode.classList.add('not-valid')
        } else {
            inputFile.current.parentNode.classList.remove('not-valid')
        }
    }

    function handleFileUpload(event) {
        const newFiles = [];

        // validFileInput([...files, ...inputFile.current.files]);

        const localFiles = [...event.target.files].filter((file) => {
            return file.type.includes('image/');
        }).filter((file, index) => {
            return index < (3 - files.length);
        })

        for(let file of localFiles) {
            let isExist = false;
            for(let fileD of files) {
                if(file.name === fileD.name) {
                    isExist = true;
                    break;
                }
            }
            if(!isExist) {
                newFiles.push(file);
            }
        }

        setFiles([...files, ...newFiles]);

    }

    useEffect(() => {

        const dataTransfer = new DataTransfer();
        for(let file of files) {
            dataTransfer.items.add(file);
        }
        inputFile.current.files = dataTransfer.files;


        setPreviews(files.map((file) => {
            return {
                src: URL.createObjectURL(file)
            }
        }));

    }, [files]);

    function markIfNotEmpty(target){
        if(!target.value.length) {
            target.classList.remove('not-empty');
        } else {
            target.classList.add('not-empty');
        }
    }

    const labelSend = 'Отправить';
    const labelLoadMedia = 'Фото';
    const labelReview = 'Отзыв';
    const labelQuestion = 'Дата посещения';
    const labelName = 'Имя';
    const labelPhone = 'Телефон';
    const labelEmail = 'Email';

    const defaultError = 'Обязательное поле';

    return (
        <Spin spinning={loading} className={classnames(styles.fullHeightSpinner, styles.spinnerSizeL)}>
            <form
                name={restaurantName}
                method='post'
                data-netlify='true'
                data-netlify-honeypot='bot'
                onBlur={e => !!e.target.dataset.required && isFormFieldValid(e.target)}
                onSubmit={e => handleSubmit(e)}
                onReset={e => handleReset(e)}
                className={styles.reviewForm}
            >
                <input type='hidden' name='bot' onChange={e => setBot(true)}/>
                <input type='hidden' name='form-name' value={restaurantName}/>
                <input type='hidden' name='for-page-id' value={pageId}/>
                <div className={classnames(styles.formStep,  !success && styles.formStepActive, 'space-bottom')}>
                    <p className={styles.formStep__title}>{beforeRatingText}</p>
                    <fieldset>
                        <svg aria-hidden="true" hidden  width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg"><symbol id="star" width="50" height="48" viewBox="0 0 50 48" fill="inherit" stroke="inherit" stroke-width="inherit" xmlns="http://www.w3.org/2000/svg"><path d="M24.049 2.163c.3-.921 1.603-.921 1.902 0l4.384 13.493a3 3 0 0 0 2.853 2.074h14.188c.97 0 1.372 1.24.588 1.808l-11.478 8.34a3 3 0 0 0-1.09 3.354l4.384 13.493c.3.922-.755 1.688-1.538 1.118l-11.479-8.339a3 3 0 0 0-3.526 0l-11.478 8.34c-.784.569-1.839-.197-1.54-1.119l4.385-13.493a3 3 0 0 0-1.09-3.354l-11.478-8.34c-.784-.569-.381-1.808.588-1.808H16.81a3 3 0 0 0 2.854-2.074l4.384-13.493Z" fill="inherit" stroke="inherit" stroke-width="inherit"/></symbol></svg>
                        <span className={styles.starsGroup} onChange={handleRatingChanged}>
                            <input type="radio" id="rating-5" name="rating" value="5" /><label for="rating-5"><svg width="50" height="48" viewBox="0 0 50 48"><use xlinkHref="#star" fill="none" stroke="#D3D8DC"></use></svg></label>
                            <input type="radio" id="rating-4" name="rating" value="4" /><label for="rating-4"><svg width="50" height="48" viewBox="0 0 50 48"><use xlinkHref="#star" fill="none" stroke="#D3D8DC"></use></svg></label>
                            <input type="radio" id="rating-3" name="rating" value="3" /><label for="rating-3"><svg width="50" height="48" viewBox="0 0 50 48"><use xlinkHref="#star" fill="none" stroke="#D3D8DC"></use></svg></label>
                            <input type="radio" id="rating-2" name="rating" value="2" /><label for="rating-2"><svg width="50" height="48" viewBox="0 0 50 48"><use xlinkHref="#star" fill="none" stroke="#D3D8DC"></use></svg></label>
                            <input type="radio" id="rating-1" name="rating" value="1" /><label for="rating-1"><svg width="50" height="48" viewBox="0 0 50 48"><use xlinkHref="#star" fill="none" stroke="#D3D8DC"></use></svg></label>
                        </span>
                    </fieldset>
                </div>
                <div className={classnames(styles.formStep, rating > 4 && !success && styles.formStepActive)}>
                    <p className={classnames(styles.formStep__text, 'space-bottom')}>Мы старались, чтобы вам было вкусно и уютно. Выберите удобный сервис, чтобы поделиться отзывом о посещении:</p>
                    <Link
                        to={gooleReviewLink}
                        className='reviews-system-button space-bottom'
                        target="_blank"
                    >
                        <img src={googleLogo} />
                    </Link>
                    { !!yandexReviewLink && <Link
                        to={yandexReviewLink}
                        className='reviews-system-button'
                        target="_blank"
                    >
                        <img src={yandexLogo} />
                    </Link> }
                    { !!yandexReviewWidget && <div className='reviews-system-button reviews-system-button_widget' dangerouslySetInnerHTML={{__html: yandexReviewWidget}}></div> }
                </div>
                <div className={classnames(styles.formStep, rating < 5 && rating > 0 && !success && styles.formStepActive)}>
                    <p className={classnames(styles.formStep__text, 'space-bottom')}>Что-то было не так? <br/>Расскажите об этом здесь, чтобы мы могли отследить и решить возможные проблемы.</p>
                    <div className={classnames('form-errors', 'space-bottom')}>
                        {formErrors.map((error, key) => (
                            <div className='form-errors__item' key={key}><img src={warningSvg} /><span>{error}</span></div>
                        ))}
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'space-bottom')}>
                        <label className='form-label'>
                            <span className='form-field-caption'>{labelName}</span>
                            <input
                                type='name'
                                name='name'
                                data-required
                                onChange={e => markIfNotEmpty(e.target)}
                            />
                            <span className='form-field-error form-field-error_empty'>{defaultError}</span>
                        </label>
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'space-bottom')}>
                        <label className='form-label'>
                            <span className='form-field-caption'>{labelEmail}</span>
                            <input
                                type='text'
                                data-type='email'
                                name='email'
                                onChange={e => markIfNotEmpty(e.target)}
                            />
                            <span className='form-field-error form-field-error_empty'>{defaultError}</span>
                            <span className='form-field-error'>Введите email в формате example@domain.com</span>
                        </label>
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'space-bottom')}>
                        <label className='form-label'>
                            <span className='form-field-caption'>{labelPhone}</span>
                            <IMaskInput
                                ref={inputTel}
                                mask='+{375}(00)000-00-00'
                                unmask={true} // true|false|'typed'
                                type='tel'
                                name='phone'
                                onInput={e => markIfNotEmpty(e.target)}
                            />
                            <span className='form-field-error form-field-error_empty'>{defaultError}</span>
                            <span className='form-field-error'>Введите номер в формате +375(XX)XXX-XX-XX</span>
                        </label>
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'space-bottom')}>
                        <label className='form-label'>
                            <span className='form-field-caption'>{labelQuestion}</span>
                            <ConfigProvider locale={locale}>
                                <DatePicker value={datePickerValue} onChange={val => setDatePickerValue(val)} disabledDate={disabledDate} size='large' inputReadOnly  name='date' format='DD/MM/YYYY'/>
                            </ConfigProvider>
                        </label>
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'space-bottom')}>
                        <label className='form-label'>
                            <span className='form-field-caption'>{labelReview}</span>
                            <textarea
                                name='text'
                                rows='7'
                                data-required
                                onChange={e => markIfNotEmpty(e.target)}
                                // autoSize={{minRows: 4, maxRows: 5}}
                            />
                            <span className='form-field-error form-field-error_empty'>{defaultError}</span>
                        </label>
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'form-field-file-row', 'space-bottom')}>
                        <label className='form-field-file'>
                            <img src={fileSvg} /><span>{labelLoadMedia}</span>
                            <input type='file' ref={inputFile} name='files[]' multiple accept="image/*" placeholder={labelLoadMedia} onChange={handleFileUpload}/>
                        </label>
                        {previews.map((preview, index) => (
                            <div className='form-field-file-preview' key={index}><button className='form-field-file-preview__remove-btn' onClick={(e) => {
                                e.preventDefault();
                                removeFile(index);
                            }}><img src={removeFileSvg}/></button><img src={preview.src} /></div>
                        ))}
                        <span className='form-field-notice'><img src={warningSvg} />Загрузите до 3 изображений</span>
                    </div>
                    <div className={classnames(styles.formRowFullWidth, 'form-footer')}>
                        <button type='submit' className={'global-button'} disabled={loading}>{labelSend}</button>
                    </div>
                </div>
                <div className={classnames(styles.formStep, styles.formStepSuccess, success && styles.formStepActive)}>
                    <div className='success-message'>
                        <img src={successSvg} />
                        <p className={styles.formStep__title}>Спасибо за ваш отзыв!</p>
                    </div>
                    <div className='success-footer'>
                        <Link
                            to='/'
                            className='global-button'
                            target="_blank"
                        >Перейти на сайт</Link>
                        <button onClick={(e) => {
                            e.preventDefault();
                            setSuccess(false);
                        }} className={styles.formButton + ' close-button'}>Зыкрыть</button>
                    </div>
                </div>
            </form>
        </Spin>
    )
}

export default ReviewIntegrationForm
