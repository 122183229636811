import React, {useEffect, useRef, useState} from 'react'
import { Carousel } from 'antd'
import * as styles from './index.module.scss'
import PictureDiz from '../pictureDiz/pictureDiz'

const RestaurantGallery = props => {
    const { gallery, address } = props
    const [modalVisibility, setModalVisibility] = useState(false)
    const slider = useRef(0)

    const openModal = (slideNum) => {
        setModalVisibility(true)
        slider.current.goTo(slideNum, true)
    }

    const modalClose = () => {
        setModalVisibility(false)
    }

    useEffect(() => {
        function handleSliderKeys(event) {
            if(modalVisibility){
                if(event.key == "Escape"){
                    modalClose()
                }else if(event.key == "ArrowRight"){
                    slider.current.next()
                }else if(event.key == "ArrowLeft"){
                    slider.current.prev()
                }
            }
        }

        document.addEventListener('keydown', handleSliderKeys);

        return () => {
            document.removeEventListener('keydown', handleSliderKeys);
        };
    });

    if(!gallery) return null;

    

    const CustomNextArrow = (props) => {
        const { onClick, slideCount, currentSlide, elemClass } = props;

        return (
            <button
                className={elemClass}
                onClick={onClick}
            >
                <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 13.9999C7 13.7678 7.09219 13.5453 7.25628 13.3812C7.42038 13.2171 7.64294 13.1249 7.875 13.1249H18.0128L14.2555 9.36939C14.0912 9.20508 13.9989 8.98224 13.9989 8.74988C13.9989 8.51753 14.0912 8.29469 14.2555 8.13038C14.4198 7.96608 14.6426 7.87378 14.875 7.87378C15.1074 7.87378 15.3302 7.96608 15.4945 8.13038L20.7445 13.3804C20.826 13.4617 20.8906 13.5582 20.9347 13.6645C20.9789 13.7708 21.0016 13.8848 21.0016 13.9999C21.0016 14.115 20.9789 14.2289 20.9347 14.3352C20.8906 14.4415 20.826 14.5381 20.7445 14.6194L15.4945 19.8694C15.3302 20.0337 15.1074 20.126 14.875 20.126C14.6426 20.126 14.4198 20.0337 14.2555 19.8694C14.0912 19.7051 13.9989 19.4822 13.9989 19.2499C13.9989 19.0175 14.0912 18.7947 14.2555 18.6304L18.0128 14.8749H7.875C7.64294 14.8749 7.42038 14.7827 7.25628 14.6186C7.09219 14.4545 7 14.2319 7 13.9999Z"/>
                </svg>
            </button>
        );
    }

    const CustomPrevArrow = (props) => {
        const { onClick, slideCount, currentSlide, elemClass } = props;

        return (
            <button
                className={elemClass}
                onClick={onClick} 
            >
                <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 14.0001C21 14.2322 20.9078 14.4547 20.7437 14.6188C20.5796 14.7829 20.3571 14.8751 20.125 14.8751L9.98725 14.8751L13.7445 18.6306C13.9088 18.7949 14.0011 19.0178 14.0011 19.2501C14.0011 19.4825 13.9088 19.7053 13.7445 19.8696C13.5802 20.0339 13.3574 20.1262 13.125 20.1262C12.8926 20.1262 12.6698 20.0339 12.5055 19.8696L7.2555 14.6196C7.17401 14.5383 7.10936 14.4418 7.06525 14.3355C7.02114 14.2292 6.99844 14.1152 6.99844 14.0001C6.99844 13.885 7.02114 13.7711 7.06525 13.6648C7.10936 13.5585 7.17401 13.4619 7.2555 13.3806L12.5055 8.13062C12.6698 7.96631 12.8926 7.87401 13.125 7.87401C13.3574 7.87401 13.5802 7.96631 13.7445 8.13062C13.9088 8.29492 14.0011 8.51776 14.0011 8.75012C14.0011 8.98247 13.9088 9.20531 13.7445 9.36962L9.98725 13.1251L20.125 13.1251C20.3571 13.1251 20.5796 13.2173 20.7437 13.3814C20.9078 13.5455 21 13.7681 21 14.0001Z"/>
                </svg>
            </button>
        );
    }

    const CustomNextArrowMod = (props) => {
        const { onClick, slideCount, currentSlide, elemClass, className } = props;

        //if (className.includes('slick-disabled')) return null

        return (
            <button
                className={elemClass}
                onClick={onClick}
            >
                <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 13.9999C7 13.7678 7.09219 13.5453 7.25628 13.3812C7.42038 13.2171 7.64294 13.1249 7.875 13.1249H18.0128L14.2555 9.36939C14.0912 9.20508 13.9989 8.98224 13.9989 8.74988C13.9989 8.51753 14.0912 8.29469 14.2555 8.13038C14.4198 7.96608 14.6426 7.87378 14.875 7.87378C15.1074 7.87378 15.3302 7.96608 15.4945 8.13038L20.7445 13.3804C20.826 13.4617 20.8906 13.5582 20.9347 13.6645C20.9789 13.7708 21.0016 13.8848 21.0016 13.9999C21.0016 14.115 20.9789 14.2289 20.9347 14.3352C20.8906 14.4415 20.826 14.5381 20.7445 14.6194L15.4945 19.8694C15.3302 20.0337 15.1074 20.126 14.875 20.126C14.6426 20.126 14.4198 20.0337 14.2555 19.8694C14.0912 19.7051 13.9989 19.4822 13.9989 19.2499C13.9989 19.0175 14.0912 18.7947 14.2555 18.6304L18.0128 14.8749H7.875C7.64294 14.8749 7.42038 14.7827 7.25628 14.6186C7.09219 14.4545 7 14.2319 7 13.9999Z"/>
                </svg>
            </button>
        );
    }

    const CustomPrevArrowMod = (props) => {
        const { onClick, slideCount, currentSlide, elemClass, className } = props;

        //if (className.includes('slick-disabled')) return null

        return (
            <button
                className={elemClass}
                onClick={onClick} 
            >
                <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 14.0001C21 14.2322 20.9078 14.4547 20.7437 14.6188C20.5796 14.7829 20.3571 14.8751 20.125 14.8751L9.98725 14.8751L13.7445 18.6306C13.9088 18.7949 14.0011 19.0178 14.0011 19.2501C14.0011 19.4825 13.9088 19.7053 13.7445 19.8696C13.5802 20.0339 13.3574 20.1262 13.125 20.1262C12.8926 20.1262 12.6698 20.0339 12.5055 19.8696L7.2555 14.6196C7.17401 14.5383 7.10936 14.4418 7.06525 14.3355C7.02114 14.2292 6.99844 14.1152 6.99844 14.0001C6.99844 13.885 7.02114 13.7711 7.06525 13.6648C7.10936 13.5585 7.17401 13.4619 7.2555 13.3806L12.5055 8.13062C12.6698 7.96631 12.8926 7.87401 13.125 7.87401C13.3574 7.87401 13.5802 7.96631 13.7445 8.13062C13.9088 8.29492 14.0011 8.51776 14.0011 8.75012C14.0011 8.98247 13.9088 9.20531 13.7445 9.36962L9.98725 13.1251L20.125 13.1251C20.3571 13.1251 20.5796 13.2173 20.7437 13.3814C20.9078 13.5455 21 13.7681 21 14.0001Z"/>
                </svg>
            </button>
        );
    }

    return (
        <section className={styles.restaurantGallery}>
            <Carousel dots={false} slidesToShow={1} variableWidth={true} slidesToScroll={1}
                      centerMode={false} className={styles.restaurantGallery__slider} arrows={true} infinite={true}
            nextArrow={ <CustomNextArrow elemClass={styles.restaurantGallery__arrow +' '+ styles.restaurantGallery__nextArrow}/> } prevArrow={<CustomPrevArrow elemClass={styles.restaurantGallery__arrow +' '+ styles.restaurantGallery__prevArrow}/> }
            >
                {gallery.map((node, index) => {
                    const image = node.localFile && node.localFile.childImageSharp && node.localFile.childImageSharp.gatsbyImageData ? node.localFile.childImageSharp.gatsbyImageData : false;
                    return (
                        <button className={styles.restaurantGallery__sliderButton} onClick={() => openModal(index) }>
                            <PictureDiz set={image} alt={address} bpsizes={[[335, 670], [335, 670], [512, 1024], [568, 1136]]}/>
                        </button>
                    )
                })}
            </Carousel>
            <div className={modalVisibility ? styles.restaurantGallery__modal : styles.restaurantGallery__modal +' '+ styles.restaurantGallery__modal_hidden}>
                <div className={styles.restaurantGallery__modalOverlay} onClick={() => modalClose()} />
                <div className={styles.restaurantGallery__modalWrapper}>
                    <div className={styles.restaurantGallery__modalInnerOverlay} onClick={() => modalClose()} />
                    <button className={styles.restaurantGallery__modalClose} onClick={() => modalClose()}>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2L22.3668 22.3125M22.3668 2L2 22.3125L22.3668 2Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <Carousel ref={slider} dots={false} slidesToShow={1} slidesToScroll={1}
                    centerMode={false} className={styles.restaurantGallery__modalSlider} arrows={true} infinite={true} 
                    nextArrow={ <CustomNextArrowMod elemClass={styles.restaurantGallery__arrow +' '+ styles.restaurantGallery__modalSliderNextArrow}/> } prevArrow={<CustomPrevArrowMod elemClass={styles.restaurantGallery__arrow +' '+ styles.restaurantGallery__modalSliderPrevArrow}/> }
                    >
                        {gallery.map((node, index) => {
                            const image = node.localFile && node.localFile.childImageSharp && node.localFile.childImageSharp.gatsbyImageData ? node.localFile.childImageSharp.gatsbyImageData : false;
                            return (
                                <PictureDiz set={image} alt={address} bpsizes={[[890, 1780], [890, 1780], [792, 1582], [910, 1820]]}/>
                            )
                        })}
                    </Carousel>
                </div>
            </div>
        </section>
    )
}

export default RestaurantGallery
