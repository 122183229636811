import React, { useState, useEffect } from 'react'
import { Carousel, Grid } from 'antd'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './addresses.module.scss'
import parse from 'html-react-parser'
import PictureDiz from '../pictureDiz/pictureDiz'

const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.left >= 0 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

const RestaurantAddresses = () => {
    const { allWp } = useStaticQuery(
        graphql`
            query {
                allWp {
                    nodes {
                        optionPage {
                            themeOptions {
                                addresses {
                                    address
                                    phone
                                    additionalDetails
                                    link {
                                        url
                                    }
                                    image {
                                        localFile {
                                            publicURL
                                            ...AddressSliderHome
                                        }
                                    }
                                    tooltipIcon {
                                           sourceUrl 
                                    }
                                    openingSoon
                                    secondaryAddresses {
                                       address
                                       phone
                                       additionalDetails
                                       link {
                                            url
                                        }
                                       image {
                                            localFile {
                                                publicURL
                                                ...AddressSliderHome
                                            }
                                       }
                                       openingSoon
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const { useBreakpoint } = Grid
    const { md } = useBreakpoint()

    const addresses = allWp.nodes[0].optionPage.themeOptions.addresses
    const slides = addresses.reduce((acc, { secondaryAddresses, ...rest }) => {
        let addedAddress = secondaryAddresses ? [...secondaryAddresses] : []
        return [...acc, rest, ...addedAddress]
    }, [])

    const cities = [...new Set(slides.reduce((acc, curr) => {
        return [...acc, curr.address.split(',')[0]]
    }, []))]
    
    const sortedSlidesSoon = [...slides.filter(slide => !slide.openingSoon), ...slides.filter(slide => slide.openingSoon)];
    const [filteredSlides, setFilteredSlides] = useState(sortedSlidesSoon)
    const [hoveredSlide, setHoveredSlide] = useState(null)

    const [activeTab, setActiveTab] = useState('tab0')

    const [lastSlideInView, setLastSlideInView] = useState(false)

    const handleCityChange = (city, id) => {
        setActiveTab(id)
        setFilteredSlides(sortedSlidesSoon.filter(slide => slide.address.includes(city)))
    }

    const lastSlideInViewUp = () => {
        const sliderElem = document.querySelector(`.${styles.slider}`)
        
        if(sliderElem != null){
            const lastSlide = sliderElem.querySelector('.slick-slide:last-child');
            
            if(lastSlide != null){
                setLastSlideInView(isInViewport(lastSlide));
            }
        }
    }

    useEffect(() => {
        lastSlideInViewUp()
    });

    const CustomNextArrow = (props) => {
        const { onClick, className } = props
        
        if (className.includes('slick-disabled') || lastSlideInView) return null

        return (
            <div
                className={styles.nextArrow}
                onClick={() => {
                    onClick()
                    setTimeout(() => {
                        lastSlideInViewUp()
                    }, 300)
                }}
            ><i className={styles.iconArrowRight} />
            </div>
        )
    }

    const CustomPrevArrow = (props) => {
        const { onClick, className } = props
        if (className.includes('slick-disabled')) return null
        return (
            <div
                className={styles.prevArrow}
                onClick={() => {
                    onClick()
                    setTimeout(() => {
                        lastSlideInViewUp()
                    }, 300)
                }}
            ><i className={styles.iconArrowLeft} />
            </div>
        )
    }

    return md ? (
        <section className={styles.wrapper}>
            <div className={'global-container'}>
                <h2 className={'global-title-h2'}>{'Одесса-мама в Беларуси'}</h2>
                <div className={styles.filterTabs}>
                    <button id={'tab0'} onClick={() => handleCityChange('', 'tab0')}
                            className={`${styles.tab} ${activeTab === `tab0` ? styles.active : ''}`}>Все рестораны
                    </button>
                    {cities && cities.map((city, i) => (<button key={i + 1} id={`tab${i + 1}`}
                    className={`${styles.tab} ${activeTab === `tab${i + 1}` ? styles.active : ''}`}
                    onClick={() => handleCityChange(city, `tab${i + 1}`)}>{city}</button>))}
                </div>

                <Carousel 
                    dots={false}
                    slidesToShow={1}
                    variableWidth={true}
                    slidesToScroll={1}
                    centerMode={false}
                    className={styles.slider}
                    arrows={true}
                    infinite={false}
                    nextArrow={<CustomNextArrow />}
                    prevArrow={<CustomPrevArrow />}
                >
                    {filteredSlides.map((slide, index) => {
                        const openingSoon = slide.openingSoon ? 'Скоро открытие' : false;
                        const image = slide.image && slide.image.localFile && slide.image.localFile.childImageSharp && slide.image.localFile.childImageSharp.gatsbyImageData ? slide.image.localFile.childImageSharp.gatsbyImageData : false;
                        const link = slide.link && slide.link.url && !openingSoon ? slide.link.url : false;
                        const phone = slide.phone ? slide.phone : false;
                        const address = slide.address ? slide.address : false;
                        const additionalDetails = slide.additionalDetails ? slide.additionalDetails : false;

                        if(image && address){
                            return (
                                <div key={`rest-addresses-${index}`} id={`slide${1}`} className={`${styles.slide} ${hoveredSlide === index ? 'active' : ''} ${openingSoon ? styles.slide_opn : ''}`}>
                                    <a href={link}>
                                        <PictureDiz set={image} alt={address} bpsizes={[[68, 136], [68, 136], [288, 576], [288, 576]]}/>
                                    </a>
                                    <div className={styles.slideCaption}>
                                        <h5><a href={link}>{address.split(',')[0]}</a></h5>
                                        <p><a href={link}>{address.split(',').slice(1).join(',')}</a></p>
                                        <p>
                                            {openingSoon &&
                                                openingSoon
                                            }
                                            {(!openingSoon && phone) &&
                                                <a href={`tel:${phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')}`}>{phone}</a>
                                            }
                                        </p>
                                    </div>
                                    <a href={link} className={styles.slideDetails}>
                                        { additionalDetails &&
                                            <>
                                                <h6>Время работы</h6>
                                                <p>{parse(additionalDetails)}</p>
                                            </>
                                        }
                                    </a>
                                </div>
                            )
                        }
                    })}
                </Carousel>
            </div>
        </section>
    ) : (
        <section className={styles.wrapper}>
            <div className={'global-container'}>
            <h2 className={'global-title-h2'}>{'Одесса-мама в Беларуси'}</h2>
            {cities.map((city, index) => {
                return (
                    <div key={`rest-add-city-${index}`} className={styles.cityBlock}>
                        <h3 className={styles.cityBlock__cityTitle}>{city}</h3>
                        <div className={styles.cityBlock__restr}>
                            {sortedSlidesSoon.map((slide, index) => {
                                const openingSoon = slide.openingSoon ? 'Скоро открытие' : false;
                                const image = slide.image && slide.image.localFile && slide.image.localFile.childImageSharp && slide.image.localFile.childImageSharp.gatsbyImageData ? slide.image.localFile.childImageSharp.gatsbyImageData : false;
                                const link = slide.link && slide.link.url && !openingSoon ? slide.link.url : false;
                                const phone = slide.phone ? slide.phone : false;
                                const address = slide.address ? slide.address : false;
        
                                if(address && slide.address.includes(city)){
                                    return (
                                        <div key={`rest-add-city-slide-${index}`} href={slide.link && slide.link.url ? slide.link.url : null} className={styles.cityBlock__restr_single}>
                                            <a className={styles.image} href={link}>
                                                <PictureDiz set={image} alt={address} bpsizes={[[68, 136], [68, 136], [288, 576], [288, 576]]}/>
                                            </a>
                                            <a className={styles.address} href={link}>{slide.address.split(',').slice(1).join(',')}</a>

                                            {openingSoon &&
                                                <p className={styles.phone}>{openingSoon}</p>
                                            }
                                            {(!openingSoon && phone) &&
                                                <a className={styles.phone} href={`tel:${phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')}`}>{phone}</a>
                                            }
                                            
                                            {!openingSoon &&
                                                <a className={styles.addressArrow} href={link}>
                                                    <i className={styles.iconArrowRight} />
                                                </a>
                                            }
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                )
            })}
            </div>
        </section>
    )
}

export default RestaurantAddresses