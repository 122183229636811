import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './addresses.module.scss'
import PictureDiz from '../pictureDiz/pictureDiz'

const RestaurantAddressesList = () => {
    const { allWp } = useStaticQuery(
        graphql`
            query {
                allWp {
                    nodes {
                        optionPage {
                            themeOptions {
                                addresses {
                                    address
                                    phone
                                    additionalDetails
                                    link {
                                        url
                                    }
                                    image {
                                        localFile {
                                            publicURL
                                            ...AddressContacts
                                        }
                                    }
                                    tooltipIcon {
                                           sourceUrl
                                    }
                                    openingSoon
                                    secondaryAddresses {
                                       address
                                       phone
                                       additionalDetails
                                       link {
                                            url
                                        }
                                       image {
                                            localFile {
                                                publicURL
                                                ...AddressContacts
                                            }
                                       }
                                       openingSoon
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const addresses = allWp.nodes[0].optionPage.themeOptions.addresses
    const restaurants = addresses.reduce((acc, { secondaryAddresses, ...rest }) => {
        let addedAddress = secondaryAddresses ? [...secondaryAddresses] : []
        return [...acc, rest, ...addedAddress]
    }, [])
    const cities = [...new Set(restaurants.reduce((acc, curr) => {
        return [...acc, curr.address.split(',')[0]]
    }, []))]

    return (
        <section className={styles.restaurants}>
            {cities.map(city => {
                const restaurantsInCity = restaurants.filter(restaurant => restaurant.address.includes(city))
                const openingRestaurants = restaurantsInCity.filter(restaurant => restaurant.openingSoon);
                const openingRestaurantsCount = openingRestaurants && openingRestaurants.length ? openingRestaurants.length : 0;

                return(
                    <>
                        {openingRestaurantsCount != restaurantsInCity.length &&
                            <h2 className={styles.restaurants__city}>{city}</h2>
                        }
                        {restaurantsInCity.map(restaurant => {
                            const openingSoon = restaurant.openingSoon ? 'Скоро открытие' : false;
                            const image = restaurant.image && restaurant.image.localFile && restaurant.image.localFile.childImageSharp && restaurant.image.localFile.childImageSharp.gatsbyImageData ? restaurant.image.localFile.childImageSharp.gatsbyImageData : false;
                            const link = restaurant.link && restaurant.link.url && !openingSoon ? restaurant.link.url : false;
                            const phone = restaurant.phone && !openingSoon ? restaurant.phone : false;
                            const address = restaurant.address ? restaurant.address : false;
                            const additionalDetails = restaurant.additionalDetails ? restaurant.additionalDetails : false;

                            if(!openingSoon && link && address){
                                return(
                                    <div className={styles.restaurants__restaurant}>
                                        <h3 className={styles.restaurants__restaurantAddress}><a href={link}>{address.replace(`${city}, `, '')}</a></h3>
                                        {openingSoon &&
                                            <p className={styles.restaurants__restaurantSoon}>{openingSoon}</p>
                                        }
                                        {(!openingSoon && phone) &&
                                            <a href={`tel:${phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')}`} className={styles.restaurants__restaurantPhone}>{phone}</a>
                                        }
                                        <a href={link} className={styles.restaurants__restaurantArrow}>
                                            <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.0117 7.63672L9.38672 13.8242C9.28125 13.9648 9.14062 14 9 14C8.85938 14 8.71875 13.9648 8.61328 13.8594C8.36719 13.6484 8.36719 13.2969 8.57812 13.0859L13.3242 7.8125H0.5625C0.246094 7.8125 0 7.56641 0 7.28516C0 7.00391 0.246094 6.6875 0.5625 6.6875H13.3242L8.57812 1.44922C8.36719 1.23828 8.36719 0.886719 8.61328 0.675781C8.82422 0.464844 9.17578 0.464844 9.38672 0.710938L15.0117 6.89844C15.2227 7.10938 15.2227 7.42578 15.0117 7.63672Z"/>
                                            </svg>
                                        </a>
                                        {additionalDetails &&
                                            <a href={link} className={styles.restaurants__restaurantDetails}>{additionalDetails}</a>
                                        }
                                        {image &&
                                            <a href={link} className={styles.restaurants__restaurantImage}>
                                                <PictureDiz set={image} alt={address} bpsizes={[[68, 136], [68, 136], [344, 688], [590, 1180]]}/>
                                            </a>
                                        }
                                    </div>
                                )
                            }
                        })}
                    </>
                )
            })}
        </section>
    )
}

export default RestaurantAddressesList