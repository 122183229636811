import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import loadable from '@loadable/component'
import Hero from '../components/Hero'
import RestaurantAddresses from '../components/RestaurantAddresses'
const ProductCategoriesForHome = loadable(() => import('../components/Product/ProductCategoriesForHome'))
const Reviews = loadable(() => import('../components/Reviews'))
const Instagram = loadable(() => import('../components/Instagram'))

const HomePage = ({data}) => {
    const {hero, reviewsSection, instagramSection, schemaOrgJson} = data
    const {menuCategories} = useStaticQuery(
        graphql`
            query {
                menuCategories : allWpTermNode(filter: { taxonomyName: { eq: "products" } }) {
                    nodes {
                        ... on WpProductCategory {
                            id
                            name
                            categorySettings {
                                showHome
                                image {
                                  altText
                                  localFile {
                                    id
                                    publicURL
                                    ...MenuHome
                                    }
                                }
                              }
                        }
                    }
                }
            }
        `
    )

    const categoriesData = menuCategories.nodes.filter(node => node && node.categorySettings && node.categorySettings.showHome);
    const contactSchema = schemaOrgJson && schemaOrgJson.schemaOrgJson ? schemaOrgJson.schemaOrgJson : null;

    return (
        <>
            {contactSchema &&
              <script type='application/ld+json' dangerouslySetInnerHTML={{__html: contactSchema}}/>
            }
            <Hero data={hero}/>
            <div className='global-inner'>
                <RestaurantAddresses />
                <ProductCategoriesForHome categories={categoriesData} />
                <Reviews data={reviewsSection} noServices={true} countDesk={6} countTab={4} reviewsNoListMargin={true}/>
                <Instagram data={instagramSection}/>
            </div>
        </>
    )
}

export default HomePage

// {
//   "@context": "https://schema.org",
//   "@type": "Restaurant",
//   "name": "Одесса-мама",
// "description": "У нас больше чем рецепты, это кулинарная история Грузии в вашей тарелке. Собирайте друзей за большим и уютным столом “Хинкальни” в Гродно.",
//   "image": "https://hinkalnyagrodno.by/static/47fee33db0a9d685340dacd96d30e6a9/87757/mobilehero.jpg",
//   "@id": "https://hinkalnyagrodno.by/contacts",
//   "url": "https://hinkalnyagrodno.by/",
//   "telephone": "+375 44 763-59-59",
//   "priceRange": "$$",
//   "menu": "https://hinkalnyagrodno.by/menu",
//   "servesCuisine": "Грузинская",
//   "acceptsReservations": "true",
//   "address": {
//     "@type": "PostalAddress",
//     "streetAddress": "Урицкого 5",
//     "addressLocality": "Гродно",
//     "postalCode": "230025",
//     "addressCountry": "BY"
//   },
//   "geo": {
//     "@type": "GeoCoordinates",
//     "latitude": 53.681394,
//     "longitude": 23.8326761
//   },
//   "openingHoursSpecification": [{
//     "@type": "OpeningHoursSpecification",
//     "dayOfWeek": [
//       "Monday",
//       "Tuesday",
//       "Wednesday",
//       "Thursday",
//       "Sunday"
//     ],
//     "opens": "12:00",
//     "closes": "23:00"
//   },{
//     "@type": "OpeningHoursSpecification",
//     "dayOfWeek": [
//       "Friday",
//       "Saturday"
//     ],
//     "opens": "12:00",
//     "closes": "00:00"
//   }],
//   "sameAs": [
//     "https://www.instagram.com/hinkalnya.grodno/",
//     "https://www.tripadvisor.ru/Restaurant_Review-g661665-d21012839-Reviews-Hinkalnya_Grodno-Grodno_Grodno_Region.html",
//     "https://www.facebook.com/hinkalnya.grodno/"
//   ] 
// }