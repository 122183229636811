// extracted by mini-css-extract-plugin
export var wrapper = "addresses-module--wrapper--Dks4v";
export var filterTabs = "addresses-module--filterTabs--M6zQZ";
export var tab = "addresses-module--tab--lO3KI";
export var active = "addresses-module--active--4wtQs";
export var slider = "addresses-module--slider--0poIJ";
export var slide = "addresses-module--slide--7LQVY";
export var slideDetails = "addresses-module--slideDetails--AGKxu";
export var slide_opn = "addresses-module--slide_opn--f2xeS";
export var slideCaption = "addresses-module--slideCaption--eoJM5";
export var nextArrow = "addresses-module--nextArrow--gAFMI";
export var prevArrow = "addresses-module--prevArrow--ZsCbF";
export var iconArrowRight = "addresses-module--iconArrowRight--GLr4h";
export var iconArrowLeft = "addresses-module--iconArrowLeft--h22ZZ";
export var cityBlock = "addresses-module--cityBlock--gSYja";
export var cityBlock__cityTitle = "addresses-module--cityBlock__cityTitle--9PvIA";
export var cityBlock__restr = "addresses-module--cityBlock__restr---fbZT";
export var cityBlock__restr_single = "addresses-module--cityBlock__restr_single--07JDx";
export var image = "addresses-module--image--pmNM7";
export var address = "addresses-module--address--a69io";
export var phone = "addresses-module--phone--bs9gy";
export var addressArrow = "addresses-module--addressArrow--c8YA1";