import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

import RestaurantTopInfo from '../components/RestaurantTopInfo'
import RestaurantGallery from '../components/RestaurantGallery'
import RestaurantInfo from '../components/RestaurantInfo'

const Reviews = loadable(() => import('../components/Reviews'))
const Promotions = loadable(() => import('../components/Promotions'))

const RestaurantPage = ({ data }) => {
	const { title, slug, reviewsSection, schemaOrgJson, uri } = data;
	// const contactSchema = `
    // {
	// 	"@context": "https://schema.org",
	// 	"@type": "Restaurant",
	// 	"name": "Хинкальня",
	// 	"description": "У нас больше чем рецепты, это кулинарная история Грузии в вашей тарелке. Собирайте друзей за большим и уютным столом “Хинкальни” в Гродно.",
	// 	"image": "https://hinkalnyagrodno.by/static/47fee33db0a9d685340dacd96d30e6a9/87757/mobilehero.jpg",
	// 	"@id": "https://hinkalnyagrodno.by/contacts",
	// 	"url": "https://hinkalnyagrodno.by/",
	// 	"telephone": "+375 44 763-59-59",
	// 	"priceRange": "$$",
	// 	"menu": "https://hinkalnyagrodno.by/menu",
	// 	"servesCuisine": "Грузинская",
	// 	"acceptsReservations": "true",
	// 	"address": {
	// 		"@type": "PostalAddress",
	// 		"streetAddress": "Урицкого 5",
	// 		"addressLocality": "Гродно",
	// 		"postalCode": "230025",
	// 		"addressCountry": "BY"
	// 	},
	// 	"geo": {
	// 		"@type": "GeoCoordinates",
	// 		"latitude": 53.681394,
	// 		"longitude": 23.8326761
	// 	},
	// 	"openingHoursSpecification": [{
	// 		"@type": "OpeningHoursSpecification",
	// 		"dayOfWeek": [
	// 		"Monday",
	// 		"Tuesday",
	// 		"Wednesday",
	// 		"Thursday",
	// 		"Sunday"
	// 		],
	// 		"opens": "12:00",
	// 		"closes": "23:00"
	// 	},{
	// 		"@type": "OpeningHoursSpecification",
	// 		"dayOfWeek": [
	// 		"Friday",
	// 		"Saturday"
	// 		],
	// 		"opens": "12:00",
	// 		"closes": "00:00"
	// 	}],
	// 	"sameAs": [
	// 		"https://www.instagram.com/hinkalnya.grodno/",
	// 		"https://www.tripadvisor.ru/Restaurant_Review-g661665-d21012839-Reviews-Hinkalnya_Grodno-Grodno_Grodno_Region.html",
	// 		"https://www.facebook.com/hinkalnya.grodno/"
	// 	] 
	// }`;

	const contactSchema = schemaOrgJson && schemaOrgJson.schemaOrgJson ? schemaOrgJson.schemaOrgJson : null;

	const { allWp } = useStaticQuery(
        graphql`
            query {
                allWp {
                    nodes {
                        optionPage {
							themeOptions {
							  addresses {
								additionalDetails
								address
								fieldGroupName
								carteByDeliveryLink
								hoursByDay
								justEatDeliveryLink
								yandexDeliverLink
								delivioDeliverLink
								phone
								social {
								  icon {
									localFile {
										publicURL
									}
								  }
								  link {
									url
								  }
								}
								promotions {
									info
									title
									promotion {
									  description
									  title
									  icon {
										localFile {
                                            publicURL
                                        }
									  }
									}
								}
								secondaryAddresses {
								  additionalDetails
								  address
								  fieldGroupName
								  phone
								  link {
									url
								  }
								  justEatDeliveryLink
								  carteByDeliveryLink
								  hoursByDay
								  yandexDeliverLink
								  delivioDeliverLink
								  social {
									icon {
										localFile {
                                            publicURL
                                        }
									}
									link {
									  url
									}
								  }
								  image {
									localFile {
										publicURL
										...QualityImage
									}
								  }
								  gallery {
									localFile {
										publicURL
										...DetailsSlider
									}
								  }
								  promotions {
									info
									title
									promotion {
									  description
									  title
									  icon {
										localFile {
                                            publicURL
                                        }
									  }
									}
								  }
								}
								gallery {
									localFile {
										publicURL
										...DetailsSlider
									}
								}
								link {
								  url
								}
							  }
							}
						  }
                    }
                }
            }
        `
    )

    const addresses = allWp.nodes[0].optionPage.themeOptions.addresses
    const restaurants = addresses.reduce((acc, { secondaryAddresses, ...rest }) => {
        let addedAddress = secondaryAddresses ? [...secondaryAddresses] : []
        return [...acc, ...addedAddress, rest]
    }, [])

    const curentRestaurant = restaurants.filter(restaurant => restaurant.link && restaurant.link.url.includes(slug))[0];

	const titleText = title ? title.replace('в', '').replace('Минске', '').replace('Гродно', '').replace('Бресте', '').replace('Витебске', '').replace('Могилеве', '').replace('Гомеле', '').replace('Минск', '').replace('Брест', '').replace('Витебск', '').replace('Могилев', '').replace('Гомель', '').replace('Одесса-мама', ''): '';

	return (
		<>
			{contactSchema &&
              <script type='application/ld+json' dangerouslySetInnerHTML={{__html: contactSchema}}/>
            }
			<div className='global-container'>
				<h1 className='global-title-h1 global-title-h1_restaurant-page'>{title}</h1>
				<RestaurantTopInfo {...curentRestaurant}/>
				<RestaurantGallery {...curentRestaurant}/>
				<RestaurantInfo {...curentRestaurant}/>
				<Promotions {...curentRestaurant}/>
				<Reviews data={reviewsSection} noContainer={true} reviewsTitle={`Отзывы о ресторане "Одесса-мама" ${titleText}`} reviewsNoListMargin={true} titleSmallMargin={true} noServices={true} countDesk={3} countTab={4} byRestaurant={uri}/>
			</div>
		</>
	)
}

export default RestaurantPage