import React from 'react'
import {Link} from 'gatsby'
import {Grid} from 'antd'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import parse from 'html-react-parser'

import * as styles from './hero.module.scss'

const Hero = props => {
    const {heroTitle, heroCta, heroBackground, heroBackgroundMob } = props.data

    const {useBreakpoint} = Grid
    const {sm} = useBreakpoint()

    const backgroundImage = sm ?
        heroBackground ? getImage(heroBackground.localFile) : null
        :
        heroBackgroundMob ? getImage(heroBackgroundMob.localFile) : null

    return (
        <section className={styles.wrapper}>
            <div className='global-container'>
                {heroTitle && <h1 className='global-title-h1'>{parse(heroTitle)}</h1>}
                {heroCta && heroCta.url && (
                    <Link
                        to={heroCta.url}
                        className='global-button hero-button'
                        {...(heroCta.target && {target: heroCta.target})}
                    >
                        {parse(heroCta.title)}
                    </Link>
                )}
            </div>
            {typeof backgroundImage === 'object' && backgroundImage !== null && (
                <div className={styles.background}>
                    <GatsbyImage
                        loading={sm ? 'lazy' : 'eager'}
                        image={backgroundImage}
                        alt='Hero image'
                    />
                </div>
            )}
        </section>
    )
}

export default Hero
