import React from 'react'
import FranchiseContent from '../components/FranchiseContent'

const FranchisePage = ({ data }) => {
	const { title, schemaOrgJson, content } = data;
	
	const contactSchema = schemaOrgJson && schemaOrgJson.schemaOrgJson ? schemaOrgJson.schemaOrgJson : null;

	return (
		<>
			{contactSchema &&
              <script type='application/ld+json' dangerouslySetInnerHTML={{__html: contactSchema}}/>
            }
			<div className='global-container'>
				<FranchiseContent title={title} content={content}/>
			</div>
		</>
	)
}

export default FranchisePage