// extracted by mini-css-extract-plugin
export var restaurantInfo = "index-module--restaurantInfo--Redra";
export var restaurantInfo__section = "index-module--restaurantInfo__section--OFF4-";
export var restaurantInfo__section_address = "index-module--restaurantInfo__section_address--cRTVr";
export var restaurantInfo__section_workingHours = "index-module--restaurantInfo__section_workingHours--oQwfx";
export var restaurantInfo__section_delivery = "index-module--restaurantInfo__section_delivery--z794+";
export var restaurantInfo__title = "index-module--restaurantInfo__title--YTrTa";
export var restaurantInfo__description = "index-module--restaurantInfo__description--ngdPy";
export var restaurantInfo__social = "index-module--restaurantInfo__social--nBeVl";
export var restaurantInfo__socialLink = "index-module--restaurantInfo__socialLink--w+2pT";
export var restaurantInfo__deliverLink = "index-module--restaurantInfo__deliverLink--p47EJ";
export var restaurantInfo__deliverLink_justEat = "index-module--restaurantInfo__deliverLink_justEat--jyVX+";
export var restaurantInfo__deliverLink_yan = "index-module--restaurantInfo__deliverLink_yan--dqkTq";