// extracted by mini-css-extract-plugin
export var wrapper = "form-module--wrapper--KfC7h";
export var formStep = "form-module--formStep--ov82d";
export var formStepActive = "form-module--formStepActive--YxNGb";
export var formStep__title = "form-module--formStep__title--KRTjq";
export var formStep__text = "form-module--formStep__text--zH6-n";
export var formStepSuccess = "form-module--formStepSuccess--bDkTb";
export var fullHeightSpinner = "form-module--fullHeightSpinner--gx5Iq";
export var spinnerSizeL = "form-module--spinnerSizeL--lgD-p";
export var starsGroup = "form-module--starsGroup--5b5q5";
export var formRow = "form-module--formRow--Kptge";
export var formRowSubmit = "form-module--formRowSubmit--D2vSP form-module--formRow--Kptge";
export var formRowCenter = "form-module--formRowCenter--sKf5V form-module--formRow--Kptge";
export var formMessage = "form-module--formMessage--seXFm";
export var formRowColumn = "form-module--formRowColumn---m1Re form-module--formRow--Kptge";
export var formColumn = "form-module--formColumn--GDrIs";
export var formItem = "form-module--formItem--KyiMY";
export var formField = "form-module--formField--xmm4R";
export var formFileUploader = "form-module--formFileUploader--j7A+L";
export var formButton = "form-module--formButton--2JvmI";
export var formLabelContent = "form-module--formLabelContent--QnYLh";
export var formLabel = "form-module--formLabel--vLloS";
export var formLoader = "form-module--formLoader--K3E8X";
export var reviewForm = "form-module--reviewForm--4QTlF";