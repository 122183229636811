// extracted by mini-css-extract-plugin
export var restaurantGallery = "index-module--restaurantGallery--28Tg9";
export var restaurantGallery__arrow = "index-module--restaurantGallery__arrow--QbtC8";
export var restaurantGallery__nextArrow = "index-module--restaurantGallery__nextArrow--tuzNq";
export var restaurantGallery__prevArrow = "index-module--restaurantGallery__prevArrow--OPKfd";
export var restaurantGallery__slider = "index-module--restaurantGallery__slider--dDlg1";
export var restaurantGallery__sliderButton = "index-module--restaurantGallery__sliderButton--rKx1j";
export var restaurantGallery__modal = "index-module--restaurantGallery__modal--AhPnb";
export var restaurantGallery__modal_hidden = "index-module--restaurantGallery__modal_hidden--eC-gJ";
export var restaurantGallery__modalOverlay = "index-module--restaurantGallery__modalOverlay--OIvum";
export var restaurantGallery__modalInnerOverlay = "index-module--restaurantGallery__modalInnerOverlay--6Q9XI";
export var restaurantGallery__modalWrapper = "index-module--restaurantGallery__modalWrapper--Hf4yz";
export var restaurantGallery__modalClose = "index-module--restaurantGallery__modalClose--mWoLV";
export var restaurantGallery__modalSlider = "index-module--restaurantGallery__modalSlider--IOIY7";
export var restaurantGallery__modalSliderNextArrow = "index-module--restaurantGallery__modalSliderNextArrow--gfxEv";
export var restaurantGallery__modalSliderPrevArrow = "index-module--restaurantGallery__modalSliderPrevArrow--CBUr1";