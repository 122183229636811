import React from 'react'
import Logo from '../components/Logo'
import ReviewIntegrationForm from '../components/Form/ReviewIntegrationForm'

const ReviewsFormPage = ({data}) => {
    const formParams = {
        title: data.title,
        pageId: data.databaseId,
        restaurantName: data.reviewsFormMeta.restaurantName, 
        beforeRatingText: data.reviewsFormMeta.beforeReviewRatingText, 
        gooleReviewLink: data.reviewsFormMeta.servicesLinks.google, 
        yandexReviewLink: data.reviewsFormMeta.servicesLinks.yandex, 
        yandexReviewWidget: data.reviewsFormMeta.servicesLinks.yandexWidget, 
    }
    return (
        <>
            <section style={{padding: '20px 0'}}>
                <div className='global-container-mobile'>
                    <Logo isLink isMobile isWhite={false} style={{marginBottom: '35px', width: '138px', height: 'auto' }} />
                    <ReviewIntegrationForm {...formParams} />
                </div>
            </section>
        </>
    )
}

export default ReviewsFormPage
