export function getQueryStringParams() {
    let urlParams,
        match,
        pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
            return decodeURIComponent(s.replace(pl, ' '))
        },
        query = window.location.search.substring(1)

    urlParams = {}
    while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])

    return urlParams
}

export function encodeData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export function stringToClass(s) {
    console.log(s);
    return s.toLowerCase().replaceAll(/\n|\s+/g, '-')
}