import React from 'react'
import {graphql} from 'gatsby'

import {stringToClass} from '../../utils'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import HomePage from '../HomePage'
import ContactsPage from '../ContactsPage'
import RestaurantPage from '../RestaurantPage'
import FranchisePage from '../FranchisePage'
import ReviewsFormPage from '../ReviewsFormPage'

const page = ({data}) => {
    const {page} = data
    const {
        content,
        databaseId,
        uri,
        seo,
        isFrontPage,
        template: {templateName},
    } = page
    const {title} = seo;
    const pageTitle = page.title;
    let isHeader = true;
    let isFooter = true;
    let templateComponent = null;
    let isGoogleMapsInFooterPromo = false;
    let isFooterPromo = isFrontPage;
    let isHideText = false;
    let isGoogleMaps = isFrontPage ? false : false;
    let isGoogleMapsOnMobile = false;
    let isHideOnDesktop = false;

    switch (templateName) {
        case 'Contact Us':
            isGoogleMapsInFooterPromo = false;
            isHideText = false;
            isFooterPromo = false;
            isGoogleMaps = false;
            isGoogleMapsOnMobile = true;
            templateComponent = <ContactsPage data={page} />
            break;
        case 'Franchise':
            isGoogleMapsInFooterPromo = false;
            isHideText = false;
            isFooterPromo = false;
            isGoogleMaps = false;
            isGoogleMapsOnMobile = false;
            templateComponent = <FranchisePage data={page} />
            break;
        case 'Restaurant':
            isGoogleMapsInFooterPromo = true;
            isHideText = false;
            isFooterPromo = false;
            isGoogleMaps = true;
            isGoogleMapsOnMobile = true;
            templateComponent = <RestaurantPage data={page} />
            break;
        case 'Reviews Form':
            isHeader = false;
            isFooter = false;
            templateComponent = <ReviewsFormPage data={page} />
            break;
        default:
            templateComponent = (
                <article className='global-container' id={`post-${databaseId}`}>
                    <h1 className='global-title-h1' dangerouslySetInnerHTML={{__html: pageTitle}}/>

                    <div className='post-inner'>
                        <div className='post-content' dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </article>
            )
    }

    return (
        <Layout
            bodyClass={isFrontPage ? 'frontPage' : stringToClass(templateName)}
            isHeader={isHeader}
            isHeaderTransparent={isFrontPage}
            isFooter={isFooter}
            isFooterPromo={isFooterPromo}
            isHideText={isHideText}
            isGoogleMaps={isGoogleMaps}
            isGoogleMapsOnMobile={isGoogleMapsOnMobile}
            isGoogleMapsInFooterPromo={isGoogleMapsInFooterPromo}
            isHideOnDesktop={isHideOnDesktop}
            pageData={page}>
            <Seo title={title ? title : page.title} meta={seo} uri={uri}/>

            {isFrontPage ? <HomePage data={page}/> : templateComponent}
        </Layout>
    )
}

export const query = graphql`
    query page($id: String!, $nextPage: String, $previousPage: String) {
        page: wpPage(id: { eq: $id }) {
            ...PageContent
        }
        nextPage: wpPage(id: { eq: $nextPage }) {
            title
            uri
        }
        previousPage: wpPage(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`

export default page


