import React from 'react'
import * as styles from './index.module.scss'
import parse from 'html-react-parser'

const FranchiseContent = props => {
    const { title, content } = props
    return (
        <article className={styles.franchiseContent}>
            <section className={styles.franchiseContent__content}>
                <h1>{title}</h1>
                {parse(content)}
            </section>
            <section className={styles.franchiseContent__form}>
                <p>Если вы хотите открыть франшизу кафе "Одесса-мама", свяжитесь с нами по телефону: <br/><br/><a href='tel:+375291800870'>+375 (29) 180-08-70</a></p>
            </section>
        </article>
    )
}

export default FranchiseContent
